import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export const PanelHeading = ({ heading, icon, isRequired }) => {
  const prevalenceTooltipText =
    "Displays how many patients have reported the selected chief complaint for that day.";
  const trendingTooltipText =
    "Displays the top 5 chief complaints with the most added visits in the past 7 days.";

  return (
    <div className="panel-heading has-text-primary">
      {!!icon && (
        <span className="icon has-text-primary mr-1 pt-1">
          <FontAwesomeIcon icon={icon} size="lg" />
        </span>
      )}
      {heading} {!!isRequired && <span className="has-text-danger"> *</span>}
      {(heading === "PREVALENCE SURVEILLANCE" ||
        heading === "TRENDING ILLNESSES") && (
        <span
          className="icon has-text-grey-light mr-1 tooltip tooltip-text is-tooltip-right is-tooltip-multiline"
          data-tooltip={
            heading === "PREVALENCE SURVEILLANCE"
              ? prevalenceTooltipText
              : trendingTooltipText
          }
        >
          <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
        </span>
      )}
    </div>
  );
};

export const PanelBlock = ({ children }) => (
  <div className="panel-block">{children}</div>
);

const Panel = props => {
  return (
    <>
      <nav className="panel mt-1">
        <PanelHeading {...props} />
        <PanelBlock {...props} />
      </nav>
    </>
  );
};

export default Panel;
