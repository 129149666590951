import React from "react";
import styles from "./consultations.module.scss";
import classNames from "classnames";
import { RECOMMENDATION } from "./headers";
import ConsultationTableRow from "./ConsultationTableRow";

const RecommendationTable = ({ values }) => (
  <table
    className={classNames(
      "table",
      "is-fullwidth",
      "is-hoverable",
      styles.isHalfColumn
    )}
  >
    <tbody>
      {Object.keys(values).length > 2 &&
        Object.keys(RECOMMENDATION).map(key => {
          return (
            <ConsultationTableRow
              key={key}
              header={RECOMMENDATION[key]}
              value={values[key]}
            >
              {key === "clinicPasses" ? values[key].join(", ") : values[key]}
            </ConsultationTableRow>
          );
        })}
    </tbody>
  </table>
);

export default RecommendationTable;
