import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import moment from "moment";
import {
  faRunning,
  faUserMd,
  faPills,
  faNotesMedical,
  faArrowLeft,
  faEdit,
  faEnvelope,
  faUserTie,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from "../../../services/api";
import PhysicalAssessmentTable from "./PhysicalAssessmentTable";
import MedicalAssessmentTable from "./MedicalAssessmentTable";
import RecommendationTable from "./RecommendationTable";
import MedicineTable from "./MedicineTable";
import Panel from "../../Templates/Forms/Panel";
import Layout from "../../../layout/Layout";
import AppContext from "../../../context/AppContext";
import ConsultationEmailModal from "../ConsultationForm/ConsultationEmailModal";
import ConsultTable from "./ConsultTable";

export const getProductTitle = (productCode, index) => {
  return api
    .get(
      `inventory/?clinic=${sessionStorage.getItem(
        "clinicId"
      )}&search=${productCode}`
    )
    .then(response => {
      if (response.data.results.length > 0) return response.data.results[0].productTitle;
    });
};

const ViewConsultation = ({ isAdmin }) => {
  const appContext = useContext(AppContext);
  const [changeLogs, setChangeLogs] = useState([]);
  let { activeConsultation } = appContext;
  if (!activeConsultation)
    activeConsultation = {
      clinic: { site: {} },
      created: "",
      inventoryLogs: [],
      chiefComplaint: [],
    };

  const [acceptsTeleconsults, setAcceptsTeleconsults] = useState(false);
  const [productTitles, setProductTitles] = useState([]);
  const [diagnosisDescription, setDiagnosisDescription] = useState("");
  const [assessmentDescription, setAssessmentDescription] = useState("");

  const getDescription = (id, type) => {
    api.get(`clinical-conditions/${id}/`).then(response => {
      if (type === "assessment")
        setAssessmentDescription(
          `${response.data.description} - ${response.data.general}`
        );
      else
        setDiagnosisDescription(
          `${response.data.description} - ${response.data.general}`
        );
    });
  };

  useEffect(() => {
    let teleconsults = sessionStorage.getItem("teleconsults");
    setAcceptsTeleconsults(teleconsults);
    if (!activeConsultation.id) navigate("/consultations");
    else {
      let promises = [];
      for (let i = 0; i < activeConsultation.inventoryLogs.length; i++) {
        promises.push(
          getProductTitle(activeConsultation.inventoryLogs[i].productCode, i)
        );
      }
      if (activeConsultation.nurseAssessment)
        getDescription(activeConsultation.nurseAssessment, "assessment");
      if (activeConsultation.doctorsDiagnosis)
        getDescription(activeConsultation.doctorsDiagnosis, "diagnosis");
      Promise.all(promises).then(response => {
        setProductTitles(response);
      });
      api
        .get(`consultations/logs/?consultation__id=${activeConsultation.id}`)
        .then(response => {
          setChangeLogs(response.data.results);
        });
    }
  }, []);

  const ConsultationTitle = () => (
    <Fragment>
      <Link to={isAdmin ? `/admin/consultations` : `/consultations`}>
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
      </Link>
      View Consultation on{" "}
      {moment(activeConsultation.created).format("MMMM D YYYY, hh:mm A")}
    </Fragment>
  );

  return (
    <Layout pageTitle={<ConsultationTitle />}>
      <div className="columns">
        <div className="column">
          <div className="tags has-addons are-medium has-text-weight-normal mb-1">
            <span className="tag">
              <FontAwesomeIcon icon={faUserTie} className="mr-1" />
              <b>Employee</b>
            </span>
            <span className="tag is-light">
              <span>
                {activeConsultation.employee &&
                  `${activeConsultation.employee.firstName[0]} ${
                    activeConsultation.employee.lastName
                  } (#${activeConsultation.employee.employeeNumber})`}
              </span>
            </span>
          </div>
        </div>

        <div className="column">
          <div className="buttons is-right">
            <button
              type="button"
              className="button"
              onClick={() => {
                appContext.useModal(
                  <ConsultationEmailModal data={activeConsultation} />,
                  "primary",
                  "GENERATE CONSULTATION EMAIL TEXT"
                );
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <span>Generate emails</span>
            </button>
            <Link to="/consultations/edit">
              <button className="button is-primary">
                <span className="icon">
                  <FontAwesomeIcon icon={faEdit} />
                </span>{" "}
                <span>Edit Consultation</span>
              </button>
            </Link>
          </div>
        </div>
      </div>

      {changeLogs.length > 0 && changeLogs[0].action === "update" && (
        <p className="is-size-5 has-text-black">
          Last Updated:{" "}
          {moment(changeLogs[0].updated).format("MMMM D YYYY, h:mm A")} by{" "}
          {changeLogs[0].updatedBy}
        </p>
      )}
      <div className="columns mt-1">
        <div className="column">
          {acceptsTeleconsults === "true" ? (
            <Panel heading="Consult Info" icon={faInfo}>
              <ConsultTable values={activeConsultation} />
            </Panel>
          ) : null}
          {activeConsultation.chiefComplaint && (
            <Panel heading="Medical Assessment" icon={faUserMd}>
              <MedicalAssessmentTable
                values={activeConsultation}
                assessment={assessmentDescription}
                diagnosis={diagnosisDescription}
              />
            </Panel>
          )}
          {activeConsultation.inventoryLogs &&
            activeConsultation.inventoryLogs.length > 0 && (
              <Panel heading="Medicines" icon={faPills}>
                <MedicineTable
                  values={activeConsultation}
                  productTitles={productTitles}
                />
              </Panel>
            )}
          <Panel heading="Recommendation" icon={faNotesMedical}>
            <RecommendationTable values={activeConsultation} />
          </Panel>
          <Panel heading="Physical Assessment" icon={faRunning}>
            <PhysicalAssessmentTable values={activeConsultation} />
          </Panel>
        </div>
      </div>
    </Layout>
  );
};

export default ViewConsultation;
