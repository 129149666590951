import React, { Fragment } from "react";
import TextArea from "./Textarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const EmailForm = ({ subject, to, toEmail, emailBody, closeModal }) => {
  return (
    <Fragment>
      <div className="field is-horizontal mt-3 mb-1">
        <div className="field-label">
          <label className="label">Subject</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">{subject}</div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal mt-2 mb-1">
        <div className="field-label my-1">
          <label className="label">To</label>
        </div>
        <div className="field-body">
          <div className="field is-grouped">{to}</div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label my-1">
          <label className="label">Email</label>
        </div>
        <div className="field-body">
          <div className="field is-grouped">
            <p className="control is-expanded">
              <input
                className="input is-static"
                name="supervisorEmail"
                type="email"
                value={toEmail}
                readOnly
              />
            </p>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <TextArea
            rows={10}
            value={emailBody}
            label="Email body (Please review and edit as needed)"
            isRequired
          />
        </div>
      </div>

      <div className="buttons is-right">
        <button
          className="button is-success is-outlined"
          type="button"
          onClick={closeModal}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <span>Done</span>
        </button>
      </div>
    </Fragment>
  );
};

export default EmailForm;
