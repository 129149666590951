import React, { useContext, useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import AppContext from "../../../context/AppContext";
import SearchBar from "../../Templates/Forms/SearchBar";
import Input from "../../Templates/Forms/Input";
import Datalist from "../../Templates/Forms/Datalist";
import EmailForm from "../../Templates/Forms/EmailForm";
// import api from "../../../services/api";

const ConsultationEmailModal = ({ data }) => {
  const EMAIL_TYPE_LIST = [
    {
      value: "Clinic Recommendation",
      label: "Clinic Recommendation",
    },
    {
      value: "Clinic Passes",
      label: "Clinic Passes",
    },
  ];

  const appContext = useContext(AppContext);
  const [emailType, setEmailType] = useState(EMAIL_TYPE_LIST[0]);
  const [emailBody, setEmailBody] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [isSearching, setIsSearching] = useState(true);

  useEffect(() => {
    if (!!data) setEmailBody(getEmailBody(data, emailType.value));
  }, [data, supervisor]);

  const handleKeyDown = event => {
    if (event.key === "Enter") event.preventDefault();
  };

  const getEmailBody = (data, type) => {
    let message = "Error generating email template";
    if (type === "Clinic Recommendation") {
      message = `Good day!\n\nPlease be advised that the patient below was seen by the clinic.\n\nName: ${
        !!data.employee ? data.employee.lastName : ""
      }, ${data.employee.firstName} ${
        !!data.employee.employeeNumber &&
        data.employee.employeeNumber.length > 4
          ? `(#${data.employee.employeeNumber})`
          : ``
      }`;
      message += `\n\nDate: ${moment(data.created).format("MMMM D, YYYY")}`;
      message += `\n\nNurse On Duty: ${appContext.userDetails.firstName} ${
        appContext.userDetails.lastName
      }`;
      message += `\n\nClinic Time in: ${moment(data.created, "HH:mm").format(
        "hh:mm A"
      )}`;
      message += `\n\nClinic Time out: ${moment(data.created, "HH:mm")
        .add(5, "minutes")
        .format("hh:mm A")}`;
      message += `\n\nAccount / LOB: ${
        data.employee.employee
          ? data.employee.employee.department
          : data.account
      }`;
      message += `\n\nSupervisor: ${supervisor.firstName ||
        ""} ${supervisor.lastName || ""} ${
        !!supervisor.email ? `(${supervisor.email})` : ""
      }`;
      message += `\n\nAction Taken: Seen by the nurse-on-duty/doctor-on- duty`;
      message += `\n\nRecommendations: ${data.recommendation}`;
    } else if (type === "Clinic Passes") {
      message = `Good day!\n\nPlease see attached Clinic Pass for ${
        data.employee.lastName
      }, ${data.employee.firstName}.`;
      message += `\n\nCLINIC PASS\n\nDate: ${moment().format("MMMM D, YYYY")}`;
      message += `\n\nTime: ${moment().format("hh:mm A")}`;
      message += `\n\nName of Employee: ${data.employee.lastName}, ${
        data.employee.firstName
      } ${
        !!data.employee.employeeNumber &&
        data.employee.employeeNumber.length > 4
          ? `(#${data.employee.employeeNumber})`
          : ``
      }`;
      message += `\n\nRecommendation / Management: Please allow employee to bring ${
        !!data.clinicPasses && data.clinicPasses.length > 0
          ? data.clinicPasses.join(", ")
          : "[pass]"
      } inside the production area.`;
      message += `\n\nEffectivity Date: ${moment().format("MMMM D, YYYY")}`;
      message += `\n\nDuration: `;
      message += `\n\nAccount / LOB: ${
        data.employee.employee
          ? data.employee.employee.department
          : data.account
      }`;
      message += `\n\nSupervisor / Team Leader: ${supervisor.firstName ||
        ""} ${supervisor.lastName || ""} ${
        !!supervisor.email ? `(${supervisor.email})` : ""
      }`;
      message += `\n\nNurse On Duty: ${appContext.userDetails.firstName} ${
        appContext.userDetails.lastName
      }`;
    }

    message += `\n\nNote: The clinic can only share limited information to safeguard data privacy.`;
    return message;
  };

  const SearchSupervisor = () => {
    return (
      <Formik initialValues={{ supervisorName: "" }}>
        <Form autoComplete="off" onKeyDown={handleKeyDown}>
          <Input
            isRequired
            name="supervisorName"
            placeholder="Search for supervisor..."
            render={({ field, form, props }) => {
              return (
                <SearchBar
                  id="searchSupervisor"
                  endpoint="consultations/employees"
                  resultDisplay={["firstName", "lastName"]}
                  additionalParams={`site_id=${appContext.siteDetails.id}`}
                  field={field}
                  form={form}
                  props={props}
                  callbackFunction={result => {
                    setSupervisor(result);
                    setIsSearching(false);
                    return result;
                  }}
                />
              );
            }}
          />
        </Form>
      </Formik>
    );
  };

  const HasSupervisor = () => (
    <Fragment>
      <p className="control">
        <input
          className="input is-static"
          value={`${supervisor.firstName || ""} ${supervisor.lastName || ""}`}
          readOnly
        />
      </p>
      <p className="control">
        <div className="buttons">
          <button
            type="button"
            className="button is-primary"
            onClick={() => setIsSearching(true)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            type="button"
            className="button is-danger is-outlined"
            onClick={() => {
              setSupervisor("");
              setIsSearching(true);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </p>
    </Fragment>
  );

  return (
    <Fragment>
      <Datalist
        defaultValue={emailType}
        placeholder="Select from the following..."
        name="emailType"
        options={EMAIL_TYPE_LIST}
        value={emailType}
        onChange={selected => {
          setEmailType(selected);
          setEmailBody(getEmailBody(data, selected.value));
        }}
      />
      <EmailForm
        subject={`${emailType.value} for 
        ${
          !!data.employee.employeeNumber &&
          data.employee.employeeNumber.length > 4
            ? `Employee #${data.employee.employeeNumber}`
            : `${data.employee.firstName} ${data.employee.lastName}`
        } 
        on ${moment().format("MMMM D, YYYY")}`}
        to={isSearching ? <SearchSupervisor /> : <HasSupervisor />}
        toEmail={supervisor.email || "No email found"}
        emailBody={emailBody}
        closeModal={() => appContext.closeModal()}
      />
    </Fragment>
  );
};

export default ConsultationEmailModal;
