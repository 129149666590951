import React from "react";

const ConsultationTableRow = ({ header, children, index }) => (
  <tr key={index} id={`${index}Row`}>
    <th id={`${index}Header`}>{header}</th>
    <td id={`${index}Data`}>{children || "-"}</td>
  </tr>
);

export default ConsultationTableRow;
