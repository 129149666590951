import React from "react";
import styles from "./consultations.module.scss";
import classNames from "classnames";
import { CONSULT_DETAILS } from "./headers";
import ConsultationTableRow from "./ConsultationTableRow";
import moment from "moment";

const ConsultTable = ({ values }) => {
  return (
    <table
      className={classNames(
        "table",
        "is-fullwidth",
        "is-hoverable",
        styles.isHalfColumn
      )}
    >
      <tbody>
        {Object.keys(values).length > 2 &&
          Object.keys(CONSULT_DETAILS).map(key => {
            return (
              <ConsultationTableRow key={key} header={CONSULT_DETAILS[key]}>
                {key === "scheduled" &&
                key !== "employeeSite" &&
                !!values.scheduled
                  ? moment(values[key]).format("MMM DD, YYYY h:mm A")
                  : values[key]}
                {key === "employeeSite" ? values.employee.site : ""}
              </ConsultationTableRow>
            );
          })}
      </tbody>
    </table>
  );
};

export default ConsultTable;
