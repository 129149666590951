export const PHYSICAL_ASSESSMENT = {
  weightKg: "Weight (kg)",
  heightCm: "Height (cm)",
  systolicMmhg: "Blood pressure (mmHg)",
  bodyTemperatureC: "Temperature (°C)",
  heartRateBpm: "Heart rate (beats per min)",
  respiratoryRateBpm: "Respiratory rate (breaths per min)",
};

export const MEDICAL_ASSESSMENT = {
  chiefComplaint: "Chief Complaint/s",
  historyOfPresentIllness: "History of Present Illness",
  nurseAssessment: "Nurse Assessment",
  doctorsDiagnosis: "Doctor's Diagnosis",
  remarkableFindings: "Remarkable Findings",
};

export const RECOMMENDATION = {
  recommendation: "Recommendation",
  notes: "Other Notes",
  clinicPasses: "Clinic Passes",
};

export const CONSULT_DETAILS = {
  consultType: "Consult Type",
  status: "Status",
  scheduled: "Scheduled Slot",
  employeeSite: "Employee Site",
};
