import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const TextArea = ({
  field,
  label,
  isRequired,
  description,
  placeholder,
  children,
  name,
  rows,
  value,
  readOnly,
}) => (
  <div className="field mt-2 mb-1">
    {!!label && (
      <label
        className={classnames({
          "has-text-weight-bold": !!isRequired,
        })}
      >
        {label}
        {!!isRequired && <span className="has-text-danger"> *</span>}
        {!!description && <span className="help">{description}</span>}
      </label>
    )}
    <div className="field-body mt-1">
      <div className="field">
        <p className="control">
          <textarea
            className="textarea has-fixed-size"
            placeholder={placeholder}
            name={name}
            rows={rows}
            value={value}
            {...field}
            readOnly={readOnly}
          >
            {children}
          </textarea>
        </p>
      </div>
    </div>
  </div>
);

const textAreaPropTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  children: PropTypes.string,
  rows: PropTypes.number.isRequired,
};

TextArea.propTypes = textAreaPropTypes;

export default TextArea;
