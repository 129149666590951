import React from "react";
import styles from "./consultations.module.scss";
import classNames from "classnames";
import { PHYSICAL_ASSESSMENT } from "./headers";
import ConsultationTableRow from "./ConsultationTableRow";

const PhysicalAssessmentTable = ({ values }) => (
  <table
    className={classNames(
      "table",
      "is-fullwidth",
      "is-hoverable",
      styles.isHalfColumn
    )}
  >
    <tbody>
      {Object.keys(values).length > 2 &&
        Object.keys(PHYSICAL_ASSESSMENT).map(key => {
          return (
            <ConsultationTableRow
              key={key}
              header={PHYSICAL_ASSESSMENT[key]}
              value={values[key]}
            >
              {key === "systolicMmhg" && !!values.systolicMmhg
                ? `${values.systolicMmhg} / ${values.diastolicMmhg}`
                : values[key]}
            </ConsultationTableRow>
          );
        })}
    </tbody>
  </table>
);

export default PhysicalAssessmentTable;
