import React from "react";
import styles from "./consultations.module.scss";
import classNames from "classnames";
import { MEDICAL_ASSESSMENT } from "./headers";
import ConsultationTableRow from "./ConsultationTableRow";

const MedicalAssessmentTable = ({ values, assessment, diagnosis }) => (
  <table
    className={classNames(
      "table",
      "is-fullwidth",
      "is-hoverable",
      styles.isHalfColumn
    )}
  >
    <tbody>
      {Object.keys(values).length > 2 &&
        Object.keys(MEDICAL_ASSESSMENT).map(key => {
          return (
            <ConsultationTableRow
              key={key}
              header={MEDICAL_ASSESSMENT[key]}
              value={
                key === "chiefComplaint" ? values[key].length > 0 : values[key]
              }
            >
              {key === "chiefComplaint" &&
                values[key].map(item => item.description).join(", ")}
              {(key === "historyOfPresentIllness" ||
                key === "remarkableFindings") &&
                values[key]}
              {key === "nurseAssessment" &&
                `${assessment} ${
                  values[`${key}Notes`] && assessment
                    ? `(${values[`${key}Notes`]})`
                    : ""
                }`}
              {key === "doctorsDiagnosis" &&
                `${diagnosis} ${
                  values[`${key}Notes`] && diagnosis
                    ? `(${values[`${key}Notes`]})`
                    : ""
                }`}
            </ConsultationTableRow>
          );
        })}
    </tbody>
  </table>
);

export default MedicalAssessmentTable;
