import React from "react";
import styles from "./consultations.module.scss";
import classNames from "classnames";
import ConsultationTableRow from "./ConsultationTableRow";

const MedicineTable = ({ values, productTitles }) => (
  <table
    className={classNames(
      "table",
      "is-fullwidth",
      "is-hoverable",
      styles.isHalfColumn
    )}
  >
    <tbody>
      {Object.keys(values).length > 2 &&
        values.inventoryLogs.map((item, index) => {
          return (
            <ConsultationTableRow
              key={index}
              header="Product dispensed"
              value={productTitles[index]}
            >
              {productTitles[index]}{" "}
              {item.quantity !== 0 && <b>#{Math.abs(item.quantity)}</b>}
            </ConsultationTableRow>
          );
        })}
    </tbody>
  </table>
);

export default MedicineTable;
